import React, { Children } from "react";
import AppStoreSvg from "../images/Download_on_the_App_Store_Badge_SE_RGB_blk_100317.svg"
import AppIcon from "../images/Vector.svg"
import bookeaseTitle from "../images/bookease.svg"
import { useNavigate } from "react-router-dom";
import '../css/footer.css'

export default function Footer() {
    const navigate = useNavigate()

    return (
        <footer className="footer">
            <div className="divHover" onClick={() => navigate("/")}>
                <img id="bookeaseImg" src={AppIcon} alt="bookeaseIcon"></img>
                <img id="bookeaseTitle" src={bookeaseTitle} alt="bookease"></img>
            </div>
            <div className="linkDiv">
                <span>
                    <p id="bookeaseMail"> Support e-mail: <a href="mailto: support@bookease.se">support@bookease.se</a></p>
                </span>
                <a href="/app/privacypolicy" target="_blank" rel="noopener noreferrer">Integritetspolicy</a>
                <a href="/app/termsofservice" target="_blank" rel="noopener noreferrer">Användarvillkor</a>

            </div>
            <div className="appLinks">
                <a href='https://apple.co/3NUmsZn'><img className="img" alt='Ladda ned på App Store' src={AppStoreSvg} /></a>
                <a href='https://play.google.com/store/apps/details?id=com.filipacchi.reactnativeapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="img" alt='Ladda ned på Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png' /></a>
            </div>
            <p id="bookeaseBottom">Bookease 2023</p>

        </footer>
    )
}