import logo from './logo.svg';
import './App.css';
import Login from './Screens/Login';
import { GlobalProvider } from './GlobalContext';
import AppRouter from './AppRouter';
import { useEffect } from 'react';
import UserProfile from './UserProfile';


function App() {

  useEffect(()=>{
    try{
      let token = localStorage.getItem("token")
      console.log(token)
      //UserProfile.setIsAuth(true)
    } catch{

    }
  },[])
  

  return (
    <GlobalProvider>
      <AppRouter/>
    </GlobalProvider>
  );
}

export default App;
