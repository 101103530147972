import React from "react";
import "../css/style.css";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../UserProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import bookeaseIcon from "../images/Vector.svg";
import bookeaseTitle from "../images/bookease.svg";

export default function Header() {
  const navigate = useNavigate();

  const LoggedIn = () => {
    return (
      <button
        onClick={() => console.log("USER")}
        className="loggedInDiv"
        style={{ fontSize: "1.5vw" }} 
      >
        <p>{UserProfile.getFirstName()}</p>
        <FontAwesomeIcon
          style={{ margin: "1vw", marginRight: "3vw" }} 
          icon={faUser}
          size="2x"
          color="#20375e"
        />
      </button>
    );
  };

  return (
    <header
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        display: "flex",
        justifyContent: "space-between", 
        alignItems: "center"
      }}
    >
      <div
        className="divHover"
        onClick={() => navigate("/")}
        style={{ alignItems: "center", display: "flex" }}
      >
        <h1 style={{display: "none"}}>Bookease</h1>
        <img
          style={{minWidth: 30, width: "5vw", margin: "1vw" }} 
          src={bookeaseIcon}
          alt="bookeaseIcon"
        />
        <img
          style={{minHeight: 20, height: "3vw", margin: "1vw" }} 
          src={bookeaseTitle}
          alt="bookease"
        />
      </div>

      {/* {UserProfile.getIsAuth() ? <LoggedIn /> : (
        <div className="authButtons">
          <button
            style={{ backgroundColor: "white", color: "black", fontSize: "1.5vw" }} 
            onClick={() => navigate("/login")}
            className="btn fourth"
          >
            Logga in
          </button>
          <button
            style={{ fontSize: "1.5vw" }} 
            onClick={() => navigate("/register")}
            className="btn fourth"
          >
            Skapa konto
          </button>
        </div>
      )} */}
    </header>
  );
}
