import { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";

const ScrollDownArrow = () => {
  const [showArrow, setShowArrow] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const arrowStyle = {
    position: "fixed",
    bottom: "30px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "10vw", 
    height: "10vw", 
    cursor: "default", 
    animation: showArrow ? "flash 10s infinite" : "none", 
    opacity: showArrow ? 1 : 0,
    transition: "opacity 0.5s",
  };

  const flashingAnimation = `
    @keyframes flash {
      0%, 50%, 100% {
        opacity: 1;
      }
      25%, 75% {
        opacity: 0;
      }
    }
  `;

  return (
    <>
      <style>{flashingAnimation}</style>
      <div style={arrowStyle}>
        {showArrow && <IoIosArrowDown size="100%" />}
      </div>
    </>
  );
};

export default ScrollDownArrow;
