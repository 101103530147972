import { useRef, useState, useEffect, useContext } from "react";
import axios from "../api/axios";
import Header from "../assets/components/Header";
import "../assets/css/csslogin.css"
import UserProfile from "../UserProfile";
import Footer from "../assets/components/Footer";

const Login = () => {
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [success, setSuccess] = useState(false);

    const LOGIN_URL = "token/"


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("SIGNIN")
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        }

        try {
            const response = await axios.post(LOGIN_URL, {
                email: user,
                password: pwd
            })
            setSuccess(true);
            axios.defaults.headers.common = { 'Authorization': `Bearer ${response.data.access}` }
            let token = { access: response.data.access, refresh: response.data.refresh }
            localStorage.setItem('token', JSON.stringify(token))
            UserProfile.setFirstName(response.data.firstName)
            UserProfile.setIsAuth(true)
            setUser("");
            setPwd("");
        } catch (err) {
            if (!err?.response) {
                setErrMsg(err.response);
            } else if (err.response?.status === 409) {
                setErrMsg("Username Taken");
            } else {
                setErrMsg("Registration Failed");
            }
            errRef.current.focus();
        }
    };

    useEffect(() => {
        document.title = "Login"
        let token = localStorage.getItem('token')
    }, [])

    useEffect(() => {
        userRef.current.focus();
    }, []);
    useEffect(() => {
        setErrMsg("");
    }, [user, pwd]);

    return (
        <div>
            <Header></Header>
            <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <>
                    {success ? (
                        <section>
                            <h1 style={{ textAlign: "center" }}>Du är inloggad!</h1>
                            <br />
                            <p>{/* <a href="#">Go to Home</a> */}</p>
                        </section>
                    ) : (
                        <section style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <p
                                ref={errRef}
                                className={errMsg ? "errmsg" : "offscreen"}
                                aria-live="assertive"
                            >
                                {errMsg}
                            </p>
                            <h1>Logga in på ditt konto</h1>
                            <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 20, width: "100vw", alignItems: "center" }}>

                                <input
                                    className= "input"
                                    type="text"
                                    id="username"
                                    placeholder="Email"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                />
                                <input
                                    className= "input"
                                    type="password"
                                    id="password"
                                    placeholder="Lösenord"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />
                                <button className="btnlogin first">Logga in</button>
                            </form>
                            <p id="DoYouNeedAnAccount">
                                Behöver du ett konto?
                                <span style={{ marginLeft: 8 }} className="line">
                                    <a id="DoYouNeedAnAccount" href="#">Skapa konto</a>
                                </span>
                            </p>
                        </section>
                    )}
                </>
            </div>
                        <Footer></Footer>
        </div>
    );
};

export default Login;