import Header from "../assets/components/Header";
import Footer from "../assets/components/Footer";
import {Helmet} from "react-helmet";
const TermsOfService = () => {

    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms Of Service</title>
            </Helmet>
            <Header></Header>
            <div style={{padding: 10, marginTop: "10%"}}>
                <h1>Terms of Service</h1>
                    <h2>Introduction</h2>
                    <p>The terms of service stated in this document applies to the users of the bookease app. The purpose of this document is to clarify the agreement made between the bookease team and the users of their service. If you do not agree to these Terms and Conditions, please refrain from using the app. To use the app you certify that you are at least 13 years old and, if you are between 13 and 18 years old, that your guardian has read and accepted these Terms of Service and consent to your use of the bookease app. By using the app, you agree to abide by these Terms of Service in their entirety.</p>
                    <h2>Terms of Use</h2>
                    <p>Anyone using the bookease app or any other service provided by the bookease team needs to accept the terms of service agreement in its entirety. To use the bookease app the user has to create an account and the user is responsible for keeping their login credentials secure and confidential. By accepting the terms of service the user agrees to follow the rules stated in this document and accepts that the bookease team has the authority to delete, ban or take other actions against any user they see fit.</p>
                    <h2>Immaterial Rights</h2>
                    <p>The content of the bookease app is either owned by or licensed to the bookease team. This means that the user does not have the right to use any of the content in the app without permission. The content mentioned can be, but is not limited to, text, images and videos. When a user uploads any content to the bookease app they give the bookease team the right to use said content.</p>
                    <h2>User Behavior</h2>
                    <p>The user agrees to not use the app in any criminal, offensive or malicious ways. Some examples of malicious behavior include, but are not limited to, on purpose trying to crash the app, perform a DDoS attack, perform SQL-injections and create multiple accounts. Every user only has the right to one account and by agreeing to these terms the user states that they only have one and will only have one account connected to the bookease app. The user is also prohibited from sharing the key used to join an association to anyone who is not personally associated with that association.</p>
                    <h2>Personal Information</h2>
                    <p>By accepting these terms of service the user agrees to let the bookease team store and use the users personal information for identification and verification of user accounts and to ensure the functionality of the bookease app. Personal information will also be collected and stored for future improvements of the bookease app. The personal information that is collected is:</p>
                    <ul>
                        <li>Email address (required)</li>
                        <li>First and last name (required)</li>
                        <li>What default language the user has on their phone (required)</li>
                        <li>Native language (optional)</li>
                        <li>Account password (required)</li>
                    </ul>
                    <p>The user possesses the right to request correction of, deletion of or access to, their personal data. To ensure that the password used by the users doesn’t fall into the wrong hands the password is encrypted before being stored in the database. The bookease team can therefore not access the passwords. Information such as email address, first and last name will be shared with the administrators of the associations that the user decides to join.</p>
                    <h2>Disclaimer</h2>
                    <p>The bookease team does not bear any responsibility for damage, losses or other problems that may arise by using the app. This includes, but is not limited to, technical errors, performance issues or damage to the user’s device.</p>
                    <h2>Change of Terms</h2>
                    <p>The bookease team has the right to change the terms of service whenever and however they see fit. If any changes are made the users of the app will be notified by email to the email address given when creating the account. Continued use of the app after a change is made is an acceptance of the new terms. You agree to immediately cease using our services if you do not accept the changes to our terms.</p>
                    <h2>Contact Information</h2>
                    <p>If you have any questions about the app or the terms of service you can contact contact@bookease.se. If you are one of the first five people to send an email with the subject text “Color theme 990001” the team will implement a new color theme in the app with your suggestion (the suggestion has to include one or two hex codes for colors).</p>

            </div>
        <Footer></Footer>
            </div>
    )
}

export default TermsOfService;