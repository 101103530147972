import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './Screens/Login';
import Home from './Screens/Home';
import Auth from './Screens/Auth';
import Register from './Screens/Register';
import UserProfile from './UserProfile';
import AppPreview from './Screens/AppPreview';
import TermsOfService from './Screens/TermsOfService';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import PriceModel from './Screens/PriceModel';
const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<AppPreview />} />
                <Route path="/app/termsofservice" element={<TermsOfService />} />
                <Route path="/app/privacypolicy" element={<PrivacyPolicy />} />
               {/*  <Route path="/kostnadskalkylator" element={<PriceModel />} /> */}
               {/*  <Route path="/home" element={<Home/>} />
                <Route path="/login" element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/app' element={<AppPreview />} /> */}
            </Routes>
        </Router>
    );
};

export default AppRouter;