var UserProfile = (function() {
    var firstName
    var isAuth

    var getFirstName = () => {
        return firstName
    }

    var getIsAuth = () => {
        return isAuth
    }

    var setFirstName = (name) => {
        firstName = name
        console.log(firstName)
    }
    var setIsAuth = (bool) => {
        isAuth = bool
        console.log(isAuth)
    }

    return {
        getFirstName: getFirstName,
        setFirstName: setFirstName,
        setIsAuth: setIsAuth,
        getIsAuth: getIsAuth
    }
})()

export default UserProfile;

