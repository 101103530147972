import React, { useEffect, useRef } from 'react';
import "../css/fadein.css"

const FadeInSection = ({ children }) => {
  const domRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const element = domRef.current;
      if (!element) return;

      const top = element.getBoundingClientRect().top;
      const isVisible = top <= window.innerHeight * 0.75;

      if (isVisible) {
        element.classList.add('fade-in-visible');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return <div ref={domRef} className="fade-in">{children}</div>;
};

export default FadeInSection;