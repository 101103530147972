import { useRef, useState, useEffect, useContext } from "react";
import axios from "../api/axios";
import Header from "../assets/components/Header";
import "../assets/css/appPreview.css"
import Footer from "../assets/components/Footer";
import FadeInSection from "../assets/components/FadeInSection";
import ScrollDownArrow from "../assets/components/ScrollDownArrow.js";
import CountUp from 'react-countup';
import {Helmet} from "react-helmet";


const AppPreview = () => {
    const [bookingAmount, setBookingAmount] = useState(0)

    useEffect(() => {
        getBookingAmount()
    }, [])

    const getBookingAmount = async () => {
        try {
            const response = await axios.get("bookings/amount/total")
            setBookingAmount(response.data)
        } catch (err) {
            console.log(err)
        }
    }
    const openMail = () => {
        var emailAddress = "contact@bookease.se";
        var subject = "Kontakt";
        var mailtoLink = "mailto:" + emailAddress + "?subject=" + encodeURIComponent(subject);

        // Open the email client using the mailto link
        window.location.href = mailtoLink;
    }

    return (
        <div className="container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bookease</title>
            </Helmet>
            <ScrollDownArrow />
            <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                <Header></Header>
                <div className="titleDiv">
                    <h1 id="title">En app för alla era bokningsbara objekt</h1>
                    <p id="subtitle">Gör tvättstugan, bastun eller pingisrummet bokningsbart digitalt, samt hantera tider och medlemmar med enkelhet</p>
                    <button onClick={openMail} className="btn fourth" style={{ margin: 0, marginBottom: 50 }}>Kontakta oss</button>
                </div>
            </div>
            <div className="screenshotDiv">
                <FadeInSection><div className="screenshotDivIn">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <h2 >Gå med i föreningar</h2>
                        <p>Alla dina föreningar på ett ställe</p>
                    </div>
                    <img id="image" src={require("../assets/images/screenshotAsso.png")} alt="App Screenshot" />
                </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="screenshotDivInRev">
                        <img id="image" src={require("../assets/images/Apple iPhone 11 Pro Max Screenshot 3.png")} alt="App Screenshot" />
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <h2 >Boka tider</h2>
                            <p>Boka enkelt tvätttiden från tv-soffan</p>
                        </div>
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="screenshotDivIn">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <h2 >Håll koll på alla bokade tider</h2>
                            <p>Se och avboka dina tider</p>
                        </div>
                        <img id="image" src={require("../assets/images/screenshotBook.png")} alt="App Screenshot" />
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="bookAmount">
                        {bookingAmount == 0 ? null : <CountUp
                            end={bookingAmount}
                            scrollSpyDelay={1000}
                            enableScrollSpy={true}
                            className="countUp"
                            scrollSpyOnce={true}
                        />}
                        <h2>bokningar gjorda genom Bookease.</h2>

                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        <h2>Intresserad?</h2>
                        <button onClick={openMail} className="btn fourth" style={{ margin: 0, marginBottom: 50 }}>Kontakta oss</button>
                    </div>
                </FadeInSection>

            </div>
            <Footer />
        </div>
    );
};

export default AppPreview;