import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import Header from '../assets/components/Header';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function PriceModel() {

    const [value, setValue] = React.useState(100);
    const [valueAsso, setValueAsso] = React.useState(1);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleSliderChangeAsso = (event, newValue) => {
        setValueAsso(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleInputChangeAsso = (event) => {
        setValueAsso(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 999) {
            setValue(999);
        }
    };
    const handleBlurAsso = () => {
        if (valueAsso < 0) {
            setValueAsso(0);
        } else if (valueAsso > 10) {
            setValueAsso(10);
        }
    };

    return (
        <div>
            <Header></Header>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "10%", flexDirection: "column", alignItems: "center   " }}>
                <h1>Kostnadskalkylator</h1>
                <Box sx={{ width: 250 }}>
                    <Typography id="input-slider" gutterBottom>
                        Antal medlemmar
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            {/* <VolumeUp /> */}
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={typeof value === 'number' ? value : 0}
                                onChange={handleSliderChange}
                                aria-labelledby="input-slider"
                                max={999}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={value}
                                size="small"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 999,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: 250 }}>
                    <Typography id="input-slider" gutterBottom>
                        Föreningar
                        <Tooltip title="Antal föreningar">
                            <Button><HelpOutlineOutlinedIcon sx={{ color: "black" }}/></Button>
                        </Tooltip>
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            {/* <VolumeUp /> */}
                        </Grid>
                        <Grid item xs>
                            <Slider
                                max={10}
                                step={1}
                                marks
                                value={typeof valueAsso === 'number' ? valueAsso : 0}
                                onChange={handleSliderChangeAsso}
                                aria-labelledby="input-slider"
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                value={valueAsso}
                                size="small"
                                onChange={handleInputChangeAsso}
                                onBlur={handleBlurAsso}
                                inputProps={{
                                    step: 1,
                                    min: 0,
                                    max: 10,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}